$(function() {
	// Enable FastClick if present
	if (typeof FastClick !== 'undefined') {
		// Don't attach to body if undefined
		if (typeof document.body !== 'undefined') {
			FastClick.attach(document.body);
		}
	}

	// Kick off every Foundation plugin
	S(document).foundation();

	// Initialize svg4everybody
	svg4everybody();

	// Initialize smooth scrolling
	initSmoothScrolling();

	// Add background to opened header
	$('[data-responsive-toggle=primaryMenu]').on('click', function () {
		$('[data-header]').toggleClass('header--open');
	});

	// Initialize counter up
	$('[data-counter-up]').counterUp({
		delay: 10,
		time: 1000,
	});

});
