function initMap() {

	var map = new google.maps.Map(document.getElementById('locationMap'), {
		zoom: 2,
		center: {lat: 0, lng: 0},
		scrollwheel: false,
		streetViewControl: false,
		mapTypeControl: false,
		styles: globals.mapStyles,
	});

	var markers = [], popups = [];

  var icons = {
    wwf: globals.basePath + '/dist/assets/img/map-markers/wwf-marker.png',
    ih: globals.basePath + '/dist/assets/img/map-markers/ih-marker.png',
  };

	var clusterSizes = [53, 56, 66, 78, 90];

	var clusterStyles = [];

	for (var i = 0, size; size = clusterSizes[i]; i++) {
    clusterStyles.push({
      url: globals.basePath + '/dist/assets/img/markerclusterer/m' + (i + 1) + '.png',
      height: size,
      width: size,
			textColor: '#fff',
    });
  }

	globals.locations.map(function (loc, i) {

		markers[i] = new google.maps.Marker({
			position: {lat: loc.lat, lng: loc.lng},
			map: map,
      icon: {
				url: icons[loc.type],
				scaledSize: new google.maps.Size(30, 37),
			}
		});

		popups[i] = new google.maps.InfoWindow({
			content: '<h5>' + loc.title + '</h5><div><a href="' + loc.url + '">' + loc.url + '</a></div>',
    });

		markers[i].addListener('click', function () {

			popups.map(function (popup) {
				popup.close();
			});

      popups[i].open(map, markers[i]);
    });
	});

	var markerCluster = new MarkerClusterer(map, markers, {
		styles: clusterStyles,
		imagePath: globals.basePath + '/dist/assets/img/markerclusterer/m',
	});

}
